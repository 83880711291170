<template>
	<div>
		<!-- Media -->
		<b-media class="mb-2">
			<template #aside>
				<b-avatar
					ref="previewEl"
					:src="userDataEdit.avatar"
					:text="avatarText(`${userDataEdit.firstname} ${userDataEdit.lastname}`)"
					:variant="`light-${resolveUserRoleVariant(userDataEdit.role)}`"
					size="90px"
					rounded
				/>
			</template>
			<h4 class="mb-1">{{ userDataEdit.firstname }} {{ userDataEdit.lastname }}</h4>
			<div class="d-flex flex-wrap">
				<b-button variant="primary" @click="$refs.refInputEl.click()">
					<input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer" />
					<span class="d-none d-sm-inline">Bild ändern</span>
					<feather-icon icon="EditIcon" class="d-inline d-sm-none" />
				</b-button>
				<b-button variant="outline-secondary" class="ml-1">
					<span class="d-none d-sm-inline" @click="removeAvatar">Bild entfernen</span>
					<feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
				</b-button>
			</div>
		</b-media>

		<!-- User Info: Input Fields -->
		<b-form>
			<b-row>
				<!-- Field: Firstname -->
				<b-col cols="12" md="4">
					<b-form-group label="Vorname" label-for="firstname">
						<b-form-input id="firstname" v-model="userDataEdit.firstname" />
					</b-form-group>
				</b-col>

				<!-- Field: Lastname -->
				<b-col cols="12" md="4">
					<b-form-group label="Nachname" label-for="lastname">
						<b-form-input id="lastname" v-model="userDataEdit.lastname" />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<!-- Field: Email -->
				<b-col cols="12" md="4">
					<b-form-group label="E-Mail" label-for="email">
						<b-form-input id="email" v-model="userDataEdit.email" type="email" />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<!-- Field: Status -->
				<b-col cols="12" md="4">
					<b-form-group label="Status" label-for="user-status">
						<v-select
							v-model="userDataEdit.status"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="statusOptions"
							:reduce="(val) => val.value"
							:clearable="false"
							input-id="user-status"
						/>
					</b-form-group>
				</b-col>

				<!-- Field: Role -->
				<b-col cols="12" md="4">
					<b-form-group label="Mitarbeiter Rolle" label-for="user-role">
						<v-select
							v-model="userDataEdit.role"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="roleOptions"
							:reduce="(val) => val.value"
							:clearable="false"
							input-id="user-role"
						/>
					</b-form-group>
				</b-col>

				<!-- Field: Company -->
				<!-- <b-col cols="12" md="4">
					<b-form-group label="Company" label-for="company">
						<b-form-input id="company" v-model="userDataEdit.company" />
					</b-form-group>
				</b-col> -->
			</b-row>

			<b-row>
				<!-- Field: Status -->
				<b-col cols="12" md="4">
					<b-form-group label="Sevdesk Benutzer" label-for="user-status">
						<v-select
							v-model="userDataEdit.settings.sevdeskUser"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="transformedSevdeskUsers"
							:reduce="(val) => val.value"
							:clearable="false"
							input-id="user-status"
							ref="sevdeskUserSelect"
						/>
					</b-form-group>
				</b-col>
			</b-row>
		</b-form>

		<!-- PERMISSION TABLE -->
		<b-card no-body class="border mt-1" disabled>
			<b-card-header class="p-1">
				<b-card-title class="font-medium-2">
					<feather-icon icon="LockIcon" size="18" />
					<span class="align-middle ml-50">Permission</span>
				</b-card-title>
			</b-card-header>
			<b-table striped responsive class="mb-0" :items="permissionsData">
				<template #cell(module)="data">
					{{ data.value }}
				</template>
				<template #cell()="data">
					<b-form-checkbox :checked="data.value" />
				</template>
			</b-table>
		</b-card>

		<!-- Action Buttons -->
		<div class="mt-2 d-flex align-items-center">
			<b-button
				variant="primary"
				class="mb-1 mb-sm-0 mr-0 mr-sm-1"
				:block="$store.getters['app/currentBreakPoint'] === 'xs'"
				@click="save"
				v-if="!saving"
			>
				Speichern
			</b-button>
			<div class="d-inline-block full-height">
				<div
					class="d-flex align-items-center justify-content-center mb-1 mb-sm-0 mr-0 mr-sm-1 full-height"
					style="width: 116px"
					v-if="saving"
				>
					<b-spinner></b-spinner>
				</div>
			</div>
			<b-button variant="outline-secondary" type="reset" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="reset">
				Zurücksetzen
			</b-button>
		</div>
	</div>
</template>

<script>
import {
	BButton,
	BMedia,
	BAvatar,
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BForm,
	BTable,
	BCard,
	BCardHeader,
	BCardTitle,
	BFormCheckbox,
	BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, watch } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'

export default {
	components: {
		BButton,
		BMedia,
		BAvatar,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BForm,
		BTable,
		BCard,
		BCardHeader,
		BCardTitle,
		BFormCheckbox,
		vSelect,
		BSpinner,
	},
	props: {
		userData: {
			type: Object,
			required: true,
		},
		sevdeskUsers: {
			type: Array,
			required: true,
		},
		resetFn: {
			type: Function,
			required: true,
		},
	},
	computed: {
		userDataEdit: {
			get() {
				return this.localUserData || this.userData
			},
			set(val) {
				this.localUserData = val
			},
		},
		transformedSevdeskUsers() {
			const users = this.sevdeskUsers.map((user) => {
				return { label: `${user.fullname} (${user.id})`, value: user.id }
			})
			return users
		},
	},
	data() {
		return {
			localUserData: null,
			inputImageRenderer: () => {},
			saving: false,
		}
	},
	mounted() {
		const refInputEl = ref(this.$refs.refInputEl)
		const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
			// eslint-disable-next-line no-param-reassign
			const userData = { ...this.userDataEdit }
			userData.avatar = base64
			this.userDataEdit = { ...userData }
		})
		this.inputImageRenderer = inputImageRenderer
	},
	setup(props) {
		const { resolveUserRoleVariant } = useUsersList()

		const roleOptions = [
			{ label: 'Admininistrator', value: 'admin' },
			{ label: 'Mitarbeiter', value: 'employe' },
			// { label: 'Editor', value: 'editor' },
			// { label: 'Maintainer', value: 'maintainer' },
			// { label: 'Subscriber', value: 'subscriber' },
		]

		const statusOptions = [
			// { label: 'Pending', value: 'pending' },
			{ label: 'Active', value: 'active' },
			{ label: 'Inactive', value: 'inactive' },
		]

		const permissionsData = [
			{
				module: 'Admin',
				read: true,
				write: false,
				create: false,
				delete: false,
			},
			{
				module: 'Staff',
				read: false,
				write: true,
				create: false,
				delete: false,
			},
			{
				module: 'Author',
				read: true,
				write: false,
				create: true,
				delete: false,
			},
			{
				module: 'Contributor',
				read: false,
				write: false,
				create: false,
				delete: false,
			},
			{
				module: 'User',
				read: false,
				write: false,
				create: false,
				delete: true,
			},
		]

		// ? Demo Purpose => Update image on click of update
		// const refInputEl = ref(null)
		const previewEl = ref(null)

		// const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
		// 	// eslint-disable-next-line no-param-reassign
		// 	props.userData.avatar = base64
		// })

		return {
			resolveUserRoleVariant,
			avatarText,
			roleOptions,
			statusOptions,
			permissionsData,

			//  ? Demo - Update Image on click of update button
			// refInputEl,
			previewEl,
			// inputImageRenderer,
		}
	},
	methods: {
		removeAvatar() {
			this.userDataEdit.avatar = null
		},
		save() {
			this.saving = true
			this.$store
				.dispatch('Employees/updateUser', this.userDataEdit)
				.then((res) => {
					this.userDataEdit = res
				})
				.finally(() => {
					setTimeout(() => (this.saving = false), 500)
				})
		},
		reset() {
			this.localUserData = null
			this.resetFn()
			this.$forceUpdate()
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
